if (1) {
    let dataVar = $('#main-filter-content').attr('data-program-src');
    $.getJSON(dataVar, {
        format: "json"
    }).done(function( response ) {

        console.log(response);
        const data =response;
        var length = response.length;
        $('.category').html('<span class="cat-title found">Found <span>' + length + '</span> Programs</span>');
     
  const resultsContainer = document.getElementById('results-container');
  const paginationContainer = document.getElementById('pagination-container');
  const searchInput = document.getElementById('search-input');
  const searchBtn = document.getElementById('search-btn');
  
  let currentPage = 1;
  const resultsPerPage = 8;
  let currentResults = [];
  let areas = [];
  let programs = [];
  let locations = [];
  // Function to display search results
  function displayResults(results) {
    
    resultsContainer.innerHTML = '';
 
    results.forEach(result => {
      const resultElem = document.createElement('div');
      resultElem.setAttribute('class', 'col-md-6');
      resultElem.innerHTML = `
      <div class="program-listings-card">
      <p class="program-listings-title h5" style="line-height: 17px;"><a href="#">${result.title}</a></p>
      <p class="program-listings-degree-type" style="line-height: 17px;"><span>Degree Type:</span> ${result.program}</p>
      <p class="program-listings-area-of-study" style="line-height: 18px;"><span>Area of Study: </span>${result.areas}</p>
      <div class="more-info">
          <p class="more-less-collapse">
              <a class="toggle-more collapsed" data-bs-toggle="collapse" href="#expand${result.id}" role="button" aria-expanded="false" aria-controls="expand${result.id}">
                  Info
              </a>
          </p>
          <div class="collapse" id="expand${result.id}">
              <div class="card card-body">
            ${result.description}
              </div>
          </div>
      </div>
  </div>
      `;
      resultsContainer.appendChild(resultElem);
    });

   
     // Initialize masonry
  var grid = new Masonry(resultsContainer, {
    itemSelector: '.col-md-6',
    percentPosition: true
  });
  $('#results-container').masonry('reloadItems');
  $('#results-container').masonry('layout');
  
  }

  function displayPagination(totalResults) {
    const totalPages = Math.ceil(totalResults / resultsPerPage);
    paginationContainer.innerHTML = '';
    
    // Add "First" button
    const firstPageLink = document.createElement('li');
    firstPageLink.setAttribute('class', 'page-item');
  
    firstPageLink.innerHTML = '<a class="page-link page-link-skip page-link-previous" title="Skip Previous" aria-label="Previous Page"><span class="fas fa-chevron-left"></span></a>';
    if (currentPage === 1) {
      firstPageLink.disabled = true;
    }
    firstPageLink.addEventListener('click', () => {
      currentPage = 1;
      updateResults();
    });
    paginationContainer.appendChild(firstPageLink);
    
    // Add page links
    for (let i = 1; i <= totalPages; i++) {
      const pageLink = document.createElement('li');
      pageLink.setAttribute('class', 'page-item');


      pageLink.innerHTML = '<a  aria-label="Page 1"  class="page-link">'+i+'</a>';
      if (i === currentPage) {
        pageLink.classList.add('active');
      }
      pageLink.addEventListener('click', () => {
        currentPage = i;
        updateResults();
      });
      paginationContainer.appendChild(pageLink);
    }
    
    // Add "Last" button
    const lastPageLink = document.createElement('li');
    lastPageLink.setAttribute('class', 'page-item');
    lastPageLink.innerHTML = '<a class="page-link page-link-skip page-link-next" title="Skip Previous" aria-label="Previous Page"><span class="fas fa-chevron-right"></span></a>';
    if (currentPage === totalPages) {
      lastPageLink.disabled = true;
    }
    lastPageLink.addEventListener('click', () => {
      currentPage = totalPages;
      updateResults();
    });
    paginationContainer.appendChild(lastPageLink);
 

  }
  
  // Function to update results based on current page and search query
  function updateResults() {
     var searchQuery = searchInput.value.toLowerCase();
    var area = [];
    const degree = [];
    $('.checkbox').each(function(){
    if (this.checked == true){
     degree.push(this.value.toLowerCase().trim());
    
     } 
    });  
    $('.checkboxArea').each(function(){
    if (this.checked == true){
        area.push(this.value.toLowerCase().trim());
    
     } 
    });  

    const filteredResults = data.filter(result => {
  if (searchQuery) {
    return (
      result.title.toLowerCase().includes(searchQuery) ||
      result.program.toLowerCase().includes(searchQuery) ||
      result.areas.some(str => str.toLowerCase().includes(searchQuery))
    );
  } else if (degree && degree.length > 0 && (area < 0  || area.length == 0)) {
    return degree.some(deg =>
      result.program.toLowerCase().includes(deg.toLowerCase())
    );
  }else if(area &&  area.length > 0 &&  (degree.length < 0 || degree.length  == 0)){
    return area.some(area1 =>
      result.areas.some(search => search.trim().toLowerCase().includes(area1))
    );
  }else if((area && area.length > 0) &&  (degree && degree.length > 0)){
   return area.some(area1 =>
      result.areas.some(search => search.trim().toLowerCase().includes(area1)) &&
      degree.some(deg =>
          result.program.toLowerCase().includes(deg.toLowerCase())
    )
    );

  } else {
    return (
      result.title.toLowerCase().includes(searchQuery) ||
      result.program.toLowerCase().includes(searchQuery) ||
      result.areas.some(str => str.toLowerCase().includes(searchQuery))
    );
  }
});


    // const filteredResults = data.filter(result =>
    //  if(searchQuery != ''){
    //     result.title.toLowerCase().includes(searchQuery)
    //   ||result.program.toLowerCase().includes(searchQuery)
    //   ||result.areas.some(str => str.toLowerCase().includes(searchQuery))
    // }else if(degree != ''){
    //     degree.some(deg =>
    // result.program.toLowerCase().includes(deg.toLowerCase())
    // )
    // }else{
    //     result.title.toLowerCase().includes(searchQuery)
    //   ||result.program.toLowerCase().includes(searchQuery)
    //   ||result.areas.some(str => str.toLowerCase().includes(searchQuery))
    // }
    //  );
   
  
      currentResults = filteredResults.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);
    displayResults(currentResults);
    displayPagination(filteredResults.length);
    var  len1 = filteredResults.length;
    $('.category').html('<span class="cat-title found">Found <span>' + len1 + '</span> Programs</span>');
    $('.checkbox').each(function(){
      if (this.checked == true){
        var valll =this.value;
        var ddd = valll.replace(/\s/g, "");
       var  html = '';
       html = `<a onClick="MyFunction('${ddd}');" class="clear-program"><span class="filter-result-item ${ddd}">${valll} </span></a>`;
        $('.category').append(html);
    
      }
      });
      $('.checkboxArea').each(function(){
        if (this.checked == true){
          var valll =this.value;
          var ddd = valll.replace(/\s/g, "");
         var  html = '';
         html = `&nbsp;&nbsp;<a style="margin-left: 05px;" onClick="MyFunction('${ddd}');" class="clear-program"><span class="filter-result-item ${ddd}">${valll} </span></a>`;
          $('.category').append(html);
      
        }
        });
  }
  $('.checkbox').on('click', function() {
    $('#search-input').val('');
  });
  $('.checkboxArea').on('click', function() {
    $('#search-input').val('');
  });

  $('.apply').on('click', function() {
    currentPage = 1;
    updateResults();

  });
 
  // Initial results display
  updateResults();


  // Add event listener to search button
  searchBtn.addEventListener('click', () => {
    $('.custom-control-input').prop('checked', false);
    currentPage = 1;
    updateResults();
  });


  function myKeyDownHandler(event) {
    if (window.matchMedia("(max-width: 1405px)").matches) {
      document.removeEventListener("keydown",myKeyDownHandler);
    }
    if (event.keyCode === 13) {
      event.preventDefault();
      searchBtn.click();
    }
  }
document.addEventListener("keydown", myKeyDownHandler);
  
  // Add event listener to search input for live search
  searchInput.addEventListener('input', () => {
    var textv =  $('#search-input').val();
    if(textv == ''){
      currentPage = 1;
      updateResults();
    }
  
  });
  
  searchInput.addEventListener('checkbox', () => {
    currentPage = 1;
    updateResults();
  });

  $('.category').on('click',function() {
    $('.clear-program').each(function(){
  
        currentPage = 1;
        updateResults();
      
    });
  });
  $('.clear-program-filters').on('click', function() {
    $('#search-input').val('');
    $('.custom-control-input').prop('checked', false);
    currentPage = 1;
    updateResults();
});
});

}
