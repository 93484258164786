if (1) {
    let dataVar = $('#directory_data').attr('data-program-src');
    $.getJSON(dataVar, {
        format: "json"
    }).done(function( data ) {
        console.log(data);
        let totalItems = data.length;
        let areas = [];
        let programs = [];
        let locations = [];
        let department = [];

        let pagelimit = 6;
        let pageTabs = Math.floor(totalItems / pagelimit);
        let currentTab = 1;

        $.each( data, function( i, item ) {
            //This appends the HTML of the particular program into the main wrapper
            $('.directory').append($(render(item)));
            // We have to create a list per each filterable category to use later to
            // populate the dropdowns
          
            // Areas of focus
            // $(item.areas).each(function(i, item) {
            //     areas.push(item.trim());
            // });

            // // Programs of interest
            // $(item.program).each(function(i, item) {
            //     programs.push(item.trim());
            // });
            
            // // Locations
            
                department.push(item.department.trim());
            
        });

        // areas = areas.filter( function(a){if (!this[a]) {this[a] = 1; return a;}}, {});
        // programs = programs.filter( function(a){if (!this[a]) {this[a] = 1; return a;}}, {} );
        department = department.filter( function(a){if (!this[a]) {this[a] = 1; return a;}}, {} );

        // We're taking from the lists above to Populate the dropdowns
        // $(areas).each(function(i, item) {
        //     $('.checkbox-filters-wrap').prepend(  $( renderLi( item, 'degrees' ) )  );
        // });
        // $(programs).each(function(i, item) {
        //     $('.areas').prepend(  $( renderLi(item, 'areas') )  );
        // });

        $(department).each(function(i, item) {
            $('.check23').prepend(  $( renderLi(item, 'locations') )  );
        });
       

        //The HTML for the individual categories lists
        function renderLi(item, category)
        {
            let title = item;
            let item_val = item.toLowerCase().split(' ').join('-').replace(/[^a-z0-9-]/gi, '');
            return '<option data-category="program" data-item="' + title + '" class="mixitup-control dropdown-cat-item checkbox-' + item_val + '" data-name="' + title + '" value=".' + item_val + '" type="checkbox" data-filter=".' + item_val + '" id="' + item_val + '" name="' + item_val + '"> <small>' + title +
                '</small></option>';
        }

        //The HTML for the individual degree card
        function render(item) {
            // console.log(item);
            let classes = 'mix  card-' + item.id + ' card-expandable ';
         
                let loc = item.department.toLowerCase().trim().split(' ').join('-');
                loc = loc.replace(/[^a-z0-9-]/gi, '');
                classes += loc + ' ';

                let name = item.name.toLowerCase().trim().split(' ').join('-');
                name = name.replace(/[^a-z0-9-]/gi, '');
                classes += name + ' ';
                
                classes += item.phone + ' ';
                classes += item.email + ' ';


            let title = item.title.trim().toLowerCase().split(' ').join('-');
            title = title.replace(/[^a-z0-9-]/gi, '');
            classes += title + ' ';

            return '<tr class="letter-'+item.letter+' '+item.office+' ' + classes + '" data-title="' + item.title.trim() + '" data-ref="mix" data-id="card-' + item.id + '">'+
            '<td><p>'+item.name+'</p></td>'+
            '<td><p>'+item.title+'</p></td>'+
            '<td><p>'+item.department+'</p></td>'+
            '<td><p><a href="'+item.phone+'">'+item.phone+'</a></p></td>'+
            '<td><p><a href="'+item.email+'">'+item.email+'</a></p></td>'+
            '</tr>';
        }

        //The main container of the mixer content
        const container = document.querySelector('[data-ref="container"]');
        //The mixer
        const mixer = mixitup(container, {
            debug: {
                enable: true,
                showWarnings: true
            },
            data: {
                uidKey: 'id', //This is required to be in the JSON list
                dirtyCheck: true
            },
            load: {
                dataset: data,
                sort: 'title:asc'
            },
            controls: {
                live: true,
                toggleLogic: 'and',
                toggleDefault: 'all'
            },
            selectors: {
                control: '.mixitup-control'
            },
            render: {
                target: render
            },
            animation: {
                enable: false
            },
            pagination: {
                limit: pagelimit,
                generatePageList: true
            },
            load: {
                page: currentTab
            },
            multifilter: {
                enable: true // disable the multifilter extension for the mixer
                //We're using a somewhat unique approach below
            },
            callbacks: {
                onMixEnd: state => {
                    pageTabs = state.totalPages;
                    updatePageNumbers();
                    program_listing_pagination();
                }
            }  
        });

        //Updated search
        let inputSearch = document.querySelector('[data-ref="input-search"]');
        let keyupTimeout;
        let mixersearch = mixitup(container, {
            callbacks: {
                onMixClick: function() {
                    // Reset the search if a filter is clicked
                    if (this.matches('[data-filter]')) {
                        inputSearch.value = '';
                    }
                }
            }
        });

        // clear filters
        inputSearch.addEventListener('keypress', function() {
            $('.filter-menu input').prop('checked', false);
            filterByString(searchValue);
        });

        // Set up a handler to listen for "keyup" events from the search input
        inputSearch.addEventListener('keyup', function() {

            let searchValue;
            if (inputSearch.value.length < 3) {
                // If the input value is less than 3 characters, don't send
                searchValue = '';
            } else {
                searchValue = inputSearch.value.toLowerCase().trim();
            }
            // Very basic throttling to prevent mixer thrashing. Only search
            // once 350ms has passed since the last keyup event
            clearTimeout(keyupTimeout);
            keyupTimeout = setTimeout(function() {
                filterByString(searchValue);
            }, 350);
        });

        /**
         * Filters the mixer using a provided search string, which is matched against
         * the contents of each target's "class" attribute.
         *
         * @param  {string} searchValue
         * @return {void}
         */

        function filterByString(searchValue) {
            //When you begin to type, wipe out any category selection:
            $('.dropdown-cat-item').prop('checked', false);
            if (searchValue) {
                // Use an attribute wildcard selector to check for matches
                mixersearch.filter('[class*="' + searchValue + '"]');
            } else {
                // If no searchValue, treat as filter('all')
                mixersearch.filter('all');
            }
            currentTab = 1;
            mixer.paginate(1);
        }

        function updatePageNumbers()
        {
            $('#main-filter-content .page-indicator').removeClass('d-none');
            $('span.cur-page-number').html(currentTab);
            $('span.total-page-numbers').html(pageTabs);
        }

        function program_listing_pagination()
        {

            let slot1, slot2, slot3, slot4, slot5;
            slot5 = pageTabs;
            if (pageTabs < 5) {
                slot5 = 0;
            }
            if (pageTabs <= 4) {
                slot1 = (pageTabs == 0) ? 0 : 1;
                slot2 = (pageTabs <= 1) ? 0 : 2;
                slot3 = (pageTabs <= 2) ? 0 : 3;
                slot4 = (pageTabs <= 3) ? 0 : 4;
            } else {
                if (currentTab < 4) {
                    slot1 = 1; slot2 = 2; slot3 = 3; slot4 = 4;
                } else if (currentTab >= (pageTabs - 4)) {
                    slot1 = pageTabs - 4;
                    slot2 = pageTabs - 3;
                    slot3 = pageTabs - 2;
                    slot4 = pageTabs - 1;
                } else {
                    slot1 = currentTab - 2;
                    slot2 = currentTab - 1;
                    slot3 = currentTab;
                    slot4 = currentTab + 1;
                }
            }

            let pagination_html = '<li class="page-item d-none d-sm-none d-md-block page-first programpg-page-item"><a class="page-link programpg-page-first" data-page="1" role="button" aria-label="First Page">First</a></li>';

            if (slot1 != 0) {
                pagination_html += '<li class="page-item programpg-page-item"><a data-page="' + slot1 + '" class="page-link page-number programpg-page-number ' + (slot1 == currentTab ? 'active' : '') + '" role="button">' + slot1 + '</a></li>';
            }

            if (slot2 != 0) {
                pagination_html += '<li class="page-item programpg-page-item"><a data-page="' + slot2 + '" class="page-link page-number programpg-page-number ' + (slot2 == currentTab ? 'active' : '') + '" role="button">' + slot2 + '</a></li>';
            }

            if (slot3 != 0) {
                pagination_html += '<li class="page-item programpg-page-item"><a data-page="' + slot3 + '" class="page-link page-number programpg-page-number ' + (slot3 == currentTab ? 'active' : '') + '" role="button">' + slot3 + '</a></li>';
            }

            if (slot4 != 0) {
                pagination_html += '<li class="page-item programpg-page-item"><a data-page="' + slot4 + '" class="page-link page-number programpg-page-number ' + (slot4 == currentTab ? 'active' : '') + '" role="button">' + slot4 + '</a></li>';
            }

            if (slot5 != 0) {
                pagination_html += '<li class="page-item programpg-page-item"><a data-page="' + slot5 + '" class="page-link page-number programpg-page-number ' + (slot5 == currentTab ? 'active' : '') + '" role="button">' + slot5 + '</a></li>';
            }

            pagination_html += '<li class="page-item d-none d-sm-none d-md-block page-last programpg-page-item"><a class="page-link programpg-page-last" role="button" aria-label="Last Page">Last</a></li>';

            $('#program-listing-pagination').html(pagination_html);

            //The first page link
            $('.programpg-page-first').on('click', function() {
                currentTab = 1;
                $('.programpg-page-number').removeClass('active');
                $('#program-listing-pagination li:nth-child(2) .programpg-page-number').addClass('active');
                //make the changes to the list
                mixer.paginate(currentTab);
                updatePageNumbers();
            });

            //The final page link
            $('.programpg-page-last').on('click', function() {
                currentTab = pageTabs;
                $('.programpg-page-number').removeClass('active');
                $('#program-listing-pagination li:nth-last-child(2) .programpg-page-number').addClass('active');
                //make the changes to the list
                mixer.paginate(currentTab);
                updatePageNumbers();
            });

            //Any other page number in the pagination list
            $('.programpg-page-number').on('click', function() {
                currentTab = $(this).data('page');
                $('.programpg-page-number').removeClass('active');
                $(this).addClass('active');
                //make the changes to the list 
                mixer.paginate(currentTab);
                updatePageNumbers();
            });

        }

        updatePageNumbers();
        program_listing_pagination();

        //The clear filters button
        $('.clear-program-filters').on('click', function() {
            $(inputSearch).val('');
            $('.dropdown-cat-item').prop('checked', false);
            mixer.filter('all');
        });

        //The Apply button within the dropdowns
        $('#list1').on('change', function() {
            //Zero out the input search box
            $(inputSearch).val('');

            let cl = '';
         
                var fix  = $(this).val()
                cl += fix+ ' ';
                console.log(cl);
         
            if (cl != '') {
                currentTab = 1;
                mixer.filter(cl);
                mixer.paginate(1);
            } else {
                currentTab = 1;
                mixer.filter('all');
                mixer.paginate(1);
            }
        });

        mixer.sort('title:asc');


        //    / Get all sortable columns
        const ths = document.querySelectorAll('th[data-sortable="true"]');
        
        // Add click event listeners to each column header
        ths.forEach(th => {
          th.addEventListener('click', () => {
            const table = th.closest('table');
            const tbody = table.querySelector('tbody');
            const rows = Array.from(tbody.querySelectorAll('tr'));
        
            // Get the index of the clicked column header
            const index = Array.from(th.parentNode.children).indexOf(th);
        
            // Determine whether to sort in ascending or descending order
            const ascending = th.classList.contains('asc');
            const direction = ascending ? 1 : -1;
        
            // Sort the rows based on the column values
            const sortedRows = rows.sort((a, b) => {
              const aCol = a.querySelector(`td:nth-child(${index + 1})`).textContent.trim();
              const bCol = b.querySelector(`td:nth-child(${index + 1})`).textContent.trim();
        
              if (aCol < bCol) return -1 * direction;
              if (aCol > bCol) return 1 * direction;
              return 0;
            });
        
            // Update the column header class, arrow indicator, and table body
            th.classList.toggle('asc');
            th.classList.toggle('desc');
            const arrow = th.querySelector('.sort-arrow');
            arrow.classList.toggle('asc', direction === 1);
            arrow.classList.toggle('desc', direction === -1);
            tbody.innerHTML = '';
            sortedRows.forEach(row => tbody.appendChild(row));
          });
        });

    });

}

