$(document).ready(function() {
    var accordionMenu = function () {

        /**
        * Element.closest() polyfill
        * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
        */
        if (!Element.prototype.closest) {
          if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
          }
          Element.prototype.closest = function (s) {
            var el = this;
            var ancestor = this;
            if (!document.documentElement.contains(el)) return null;
            do {
              if (ancestor.matches(s)) return ancestor;
              ancestor = ancestor.parentElement;
            } while (ancestor !== null);
            return null;
          };
        }
  
        // Listen for click on the document
        // Accordiom menu functionality
        document.addEventListener('click', function (event) {
  
          // Bail if our clicked element doesn't match
          var trigger = event.target.closest('[data-accordion-menu]');
          if (!trigger) return;
  
          // Get the target content
          var target = document.querySelector(trigger.hash);
          if (!target) return;
  
          // Prevent default link behavior
          event.preventDefault();
  
          // Toggle our content
          target.classList.toggle('accordion-menu--hidden');
  
          // Toggle trigger class
          trigger.classList.toggle('accordion-menu--active');
        });
  
        // Listen for click on the document
        // Accordion parent menu functionality
        document.addEventListener('click', function (event) {
  
          // Bail if our clicked element doesn't match
          var trigger = event.target.closest('[data-accordion-menu-nav]');
          if (!trigger) return;
  
          // Get the target content
          var target = document.querySelector(trigger.hash);
          if (!target) return;
  
          // Prevent default link behavior
          event.preventDefault();
  
          // If the content is already expanded, collapse it and quit
          if (target.classList.contains('active')) {
            target.classList.remove('active');
            return;
          }
          
          // Get all open accordion content, loop through it, and close it
          var accordions = document.querySelectorAll('[data-accordion-wrapper]');
          for (var i = 0; i < accordions.length; i++) {
            accordions[i].classList.remove('active');
          }
          
          // Toggle our content
          target.classList.toggle('active');
        });
  
      };
  
      accordionMenu();
})